<template>
    <div id="activity">
        <div
            class="fixed flex items-center justify-center py-4 duration-700 rounded-full cursor-pointer buy"
        >
            <div @click="goPage">
                <!-- <div class="flex items-center justify-center">
                    <img src="../../../assets/images/buy.png" alt />
                </div>-->
                <div class="font-bold text">
                    <p>立</p>
                    <p>即</p>
                    <p>购</p>
                    <p>买</p>
                </div>
                <div
                    class="text2"
                >￥{{ pageType == 'poster' ? (posterInfo.productPrice * 1).toFixed(0) : (productInfo.discountPrice * 1).toFixed(0) }}</div>
            </div>
        </div>
        <div class="relative flex justify-center w-full activity_box imgs_box">
            <div class="inner_b_box">
                <img :src="picUrl[0]" alt v-if="picUrl.length > 0" class="w-full" @load="loadImage" />
                <img
                    src="../../../assets/images/activity/pc/activity11.png"
                    v-if="picUrl.length == 0 && pageType == 'product'"
                    alt
                    class="w-full"
                />
                <!-- <img src="../../../assets/images/activity/pc/activity11.png" alt class="pc_show" /> -->
                <!-- <img src="../../../assets/images/activity/phone/activity6.png" alt class="phone_show" /> -->
                <!-- <div class="absolute duration-500 bottom-2 text_box">
                <div
                    class="text-white title"
                >{{ pageType == 'poster' ? posterInfo.productName : productInfo.commodityName }}</div>
                <div
                    class="py-1 text-white desc"
                    v-if="pageType == 'poster'"
                >{{ posterInfo.productDescribe }}</div>
                <div
                    class="pb-1 font-bold price text-primary"
                >{{ pageType == 'poster' ? posterInfo.productPrice : productInfo.discountPrice }}元</div>
                <div class="flex items-center justify-center">
                    <div
                        class="flex items-center justify-center text-white cursor-pointer btn"
                        @click="goPage"
                    >立即购买</div>
                </div>
                </div>-->
            </div>
        </div>
        <div class="flex justify-center pb-4 sm:pb-8">
            <div class="inner_b_box" :style="{ display: picUrl.length > 1 ? 'block' : 'none' }">
                <div
                    v-for="(item, index) in picUrl"
                    :key="item"
                    :style="{ display: index > 0 ? 'block' : 'none' }"
                    class="imgs_box"
                >
                    <img
                        :src="item"
                        alt
                        :style="{ display: index > 0 ? 'block' : 'none' }"
                        class="w-full"
                        @load="loadImage"
                    />
                    <!-- <div>{{ index }}</div> -->
                </div>
            </div>
        </div>
        <!-- <div class="phone_show">
            <img
                v-for="(item,index) in phoneImgList"
                :key="index"
                :src="item.url"
                alt
                class="w-full"
            />
        </div>-->
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            pcImgList: [
                // { url: require("../../../assets/images/activity/pc/activity1.png") },
                // { url: require("../../../assets/images/activity/pc/activity2.png") },
                // { url: require("../../../assets/images/activity/pc/activity3.png") },
                // { url: require("../../../assets/images/activity/pc/activity4.png") },
                // { url: require("../../../assets/images/activity/pc/activity5.png") },
                // { url: require("../../../assets/images/activity/pc/activity6.png") },
                // { url: require("../../../assets/images/activity/pc/activity7.png") },
                // { url: require("../../../assets/images/activity/pc/activity8.png") },
                // { url: require("../../../assets/images/activity/pc/activity9.png") },
                // { url: require("../../../assets/images/activity/pc/activity10.png") }
            ],
            phoneImgList: [
                // { url: require("../../../assets/images/activity/phone/activity6.png") },
                // { url: require("../../../assets/images/activity/phone/activity1.png") },
                // { url: require("../../../assets/images/activity/phone/activity2.png") },
                // { url: require("../../../assets/images/activity/phone/activity3.png") },
                // { url: require("../../../assets/images/activity/phone/activity4.png") },
                // { url: require("../../../assets/images/activity/phone/activity5.png") },
                // { url: require("../../../assets/images/activity/phone/activity6.png") },

            ],
            posterId: "",
            posterInfo: {
                linkUrl: "",
                mainPicture: "",
                posterDetailId: "",
                posterId: "",
                productDescribe: "",
                productName: "",
                picUrl: [],
                productPrice: "",
            },
            productInfo: {
                categoryId: "2",
                commodityId: 1,
                commodityLinks: "",
                commodityName: "",
                discountPrice: "",
                masterUrl: "",
                originalPrice: "",
                picUrls: [],
                posterUrl: ""
            },
            picUrl: [],
            pageType: "",
            commodityId: "",
            loadIndex: 0,
        };
    },
    computed: {
        ...mapGetters(['getLoginStatus', 'getUserInfo'])
    },
    created() {
        this.pageType = this.$route.query.pageType
        if (this.pageType == "product") { // 商品页面
            this.commodityId = this.$route.query.commodityId
            this.getProductInfo()
        } else { // 海报详情
            this.posterId = this.$route.query.posterId
            this.getPosterInfo()

        }

    },
    watch: {
        "$route": function () {
            console.log("页面参数", this.$route)
            this.pageType = this.$route.query.pageType
            if (this.pageType == "product") { // 商品页面
                this.commodityId = this.$route.query.commodityId
                this.getProductInfo()
            } else { // 海报详情
                this.posterId = this.$route.query.posterId
                this.getPosterInfo()
            }
        }
    },
    methods: {
        initData() {
            this.pageType = this.$route.query.pageType
            console.log("pageType", this.pageType)
            if (this.pageType == "product") { // 商品页面
                this.commodityId = this.$route.query.commodityId
                this.getProductInfo()
            } else { // 海报详情
                this.posterId = this.$route.query.posterId
                this.getPosterInfo()
            }
        },
        // 获取海报详情
        getPosterInfo() {
            this.$request({
                method: "get",
                url: this.$requestPath.findPosterDetail,
                params: {
                    posterId: this.posterId
                }
            }).then(res => {
                console.log("获取海报详情", res)
                if (res.code == 0) {
                    this.picUrl = []
                    this.picUrl.push(...res.data.picUrl)
                    this.posterInfo = Object.assign({}, this.posterInfo, res.data)
                    // this.$nextTick(() => {
                    //     this.observer()
                    // })
                }
            }).catch(error => {
                console.log("获取海报详情失败", error)
            })
        },
        // 获取商品详情
        getProductInfo() {
            let params = {
                commodityId: this.commodityId,
            }
            if (this.getLoginStatus) {
                params.userId = this.getUserInfo.userId
            }
            this.$request(
                {
                    method: "get",
                    url: this.$requestPath.GoodsDetails,
                    params
                }
            ).then(res => {
                console.log("获取商品详情", res)
                if (res.code == 0) {
                    this.picUrl = []
                    this.picUrl.push(...res.data.picUrls)
                    this.productInfo = Object.assign({}, this.productInfo, res.data)
                    // this.$nextTick(() => {
                    //     this.observer()
                    // })
                }
            }).catch(error => {
                console.log("获取商品详情失败", error)
            })
        },
        loadImage() {
            this.loadIndex++
            if (this.loadIndex === 1) {
                this.observer()
            }
        },
        goPage() {
            let url = ""
            this.pageType == "poster" ? url = this.productInfo.linkUrl : url = this.productInfo.commodityLinks
            console.log("url", url)
            window.open(url)
        },
        observer() {
            let list = document.querySelectorAll('.imgs_box')
            let observer = new IntersectionObserver((entries) => {
                entries.forEach((element) => {
                    if (element.isIntersecting) {
                        element.target.classList.add('animation_show') // 增加show类名
                        observer.unobserve(element.target) // 移除监听
                    }
                })
            }, {
                threshold: 0.1
            })
            list.forEach((item) => observer.observe(item))
        }
    },
};
</script>

<style lang='scss' scoped>
.activity_box {
    min-height: var(--min-height);
}
.text_box {
    left: 50%;
    transform: translateX(-50%);
    bottom: 1.25rem;
}
@media screen and(max-width:640px) {
    .inner_b_box {
        width: 100%;
    }
    .buy {
        width: 3.125rem;
        // background: white;
        box-shadow: 0px 0px 0.625rem 0px rgba(0, 0, 0, 0.1);
        background: linear-gradient(
            to right,
            #ff8f44 0%,
            #da4a1d 50%,
            #c51117 100%
        );
        top: 50%;
        right: 0.625rem;
        transform: translateY(40%);
        z-index: 8;
        color: white;

        img {
            width: 1.5rem;
        }
        .text {
            font-size: 15px;
        }
        .text2 {
            font-size: 12px;
        }
    }
    .phone_show {
        display: block;
    }
    .pc_show {
        display: none;
    }
    .text_box {
        width: 85%;
    }
    .title {
        font-size: 0.9375rem;
    }
    .desc {
        font-size: 0.8125rem;
    }
    .price {
        font-size: 0.875rem;
    }
    .btn {
        font-size: 0.875rem;
        width: 11.25rem;
        height: 1.5625rem;
        background: var(--primary);
    }
}
.imgs_box {
    opacity: 0;
}

@media screen and(min-width:640px) {
    .inner_b_box {
        width: 49.375rem;
    }
    .buy {
        width: 3.75rem;
        // height: 3.125rem;
        // background: white;
        box-shadow: 0px 0px 0.625rem 0px rgba(0, 0, 0, 0.1);
        background: linear-gradient(
            to right,
            #ff8f44 0%,
            #da4a1d 50%,
            #c51117 100%
        );
        top: 50%;
        right: 0.625rem;
        transform: translateY(40%);
        z-index: 8;
        color: white;
        img {
            width: 1.5rem;
        }
        .text {
            font-size: 19px;
        }
        .text2 {
            font-size: 14px;
        }
    }
    .phone_show {
        display: none;
    }
    .pc_show {
        display: block;
    }
    .text_box {
        bottom: 1.25rem;
    }
    .title {
        font-size: 1rem;
    }
    .desc {
        font-size: 0.875rem;
    }
    .price {
        font-size: 1rem;
    }
    .btn {
        font-size: 1rem;
        width: 18.75rem;
        height: 2.5rem;
        background: var(--primary);
    }
}
@media screen and(min-width:800px) {
    .text_box {
        bottom: 0.625rem;
    }
}
@media screen and(min-width:1200px) {
    .text_box {
        bottom: 2.5rem;
    }
}
@media screen and(min-width:1400px) {
    .text_box {
        bottom: 3.75rem;
    }
}
</style>